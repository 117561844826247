require('bootstrap');
require('bootstrap/dist/css/bootstrap.css');

require('@fortawesome/fontawesome-free/css/all.min.css');

require('../css/app.scss');

const $ = require('jquery');
window.jQuery = $;
window.Popper = require('popper.js');


$( document ).ready(function() {
    let hamburger = document.querySelector(".hamburger");
    let sidenav = document.querySelector(".sidenav");
    // On click
    hamburger.addEventListener("click", function() {
        // Toggle class "is-active"
        hamburger.classList.toggle("is-active");
        sidenav.classList.toggle("sidenav-open");

    });
});